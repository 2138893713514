import gql from "graphql-tag";

const cities = gql`
  query Cities($citiesInput: CitiesInput!, $pagination: PaginationInput) {
    cities(cities: $citiesInput, pagination: $pagination) {
      entries {
        id
        name
      }
      pagination {
        currentPage
        nextPage
        previousPage
        totalPages
      }
    }
  }
`;

export default cities;
