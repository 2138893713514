var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-user-form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"form-field"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Email")]),_c('input',{staticClass:"text-input",attrs:{"id":"email","name":"email","type":"email","placeholder":"Type email of user here"},domProps:{"value":_vm.email},on:{"input":_vm.inputEmail}}),(_vm.helper.email)?_c('span',{staticClass:"helper"},[_vm._v(_vm._s(_vm.helper.email))]):_vm._e()]),_c('div',{staticClass:"form-field"},[_c('label',{attrs:{"for":"firstName"}},[_vm._v("First name")]),_c('input',{staticClass:"text-input",attrs:{"id":"firstName","name":"firstName","type":"text","placeholder":"Type first name of user here"},domProps:{"value":_vm.firstName},on:{"input":_vm.inputFirstName}}),(_vm.helper.firstName)?_c('span',{staticClass:"helper"},[_vm._v(_vm._s(_vm.helper.firstName))]):_vm._e()]),_c('div',{staticClass:"form-field"},[_c('label',{attrs:{"for":"lastName"}},[_vm._v("Last name")]),_c('input',{staticClass:"text-input",attrs:{"id":"lastName","name":"lastName","type":"text","placeholder":"Type last name of user here"},domProps:{"value":_vm.lastName},on:{"input":_vm.inputLastName}}),(_vm.helper.lastName)?_c('span',{staticClass:"helper"},[_vm._v(_vm._s(_vm.helper.lastName))]):_vm._e()]),_c('div',{staticClass:"form-field"},[_c('label',{attrs:{"for":"roles"}},[_vm._v("Roles")]),_c('div',[_c('label',{staticClass:"typo__label"},[_vm._v("Select role(s)")]),_c('multiselect',{attrs:{"options":_vm.adminRolesOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Pick some","label":"label","track-by":"value"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v("Selected roles: "+_vm._s(_vm.joinRoles(_vm.roles)))]):_vm._e()]}}]),model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}})],1)]),(_vm.selectedRoles.includes('CITY_MANAGER'))?_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"typo__label"},[_vm._v("Select citie(s)")]),_c('multiselect',{attrs:{"options":_vm.citiesOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Pick some","label":"label","track-by":"value"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.cityIds.length)+" selected")]):_vm._e()]}}],null,false,3167227511),model:{value:(_vm.cityIds),callback:function ($$v) {_vm.cityIds=$$v},expression:"cityIds"}}),(_vm.helper.cities)?_c('span',{staticClass:"helper"},[_vm._v(_vm._s(_vm.helper.cities))]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"form-field"},[_c('label',{attrs:{"for":"roles"}},[_vm._v("Clients")]),_c('div',[_c('label',{staticClass:"typo__label"},[_vm._v("Select client(s)")]),_c('multiselect',{attrs:{"options":_vm.clientsOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Pick some","label":"label","track-by":"value"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.customerClientIds.length)+" selected")]):_vm._e()]}}]),model:{value:(_vm.customerClientIds),callback:function ($$v) {_vm.customerClientIds=$$v},expression:"customerClientIds"}})],1)]),_c('input',{staticClass:"jaf-submit",attrs:{"type":"submit","value":"Create new user"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }