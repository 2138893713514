export const adminRoles = Object.freeze({
  admin: "admin",
  superAdmin: "superadmin",
  adManager: "ad_manager",
  clientManager: "client_manager",
  statisticsManager: "statistics_manager",
  client: "client",
  tagger: "tagger",
  tagManager: "tag_manager",
  cityManager: "city_manager"
});

export const CURRENT_USER = "current-user";
